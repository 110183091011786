import React from 'react'
import styles from './index.module.scss'
import appstoreImg from '../img/appstore.svg';
import playstoreImg from '../img/playstore.svg';
import ctaImg from '../img/cta.png';
import sunburstImg from '../img/sunburst.png';
import sunburstMobileImg from '../img/sunburst-mobile.png';

const HomepageTemplate = ({
  html,
  title,
  subtitle,
  ctatitle,
  appstore,
  appstoreIcon,
  playstore,
  playstoreIcon,
  headerimage,
  headerimageMobile,
  supplementaryimagemobile,
  supplementaryimage,
  characterimage
}) => {

  const appStoreImagePath = appstoreIcon ? appstoreIcon : appstoreImg;
  const playStoreImagePath = playstoreIcon ? playstoreIcon : playstoreImg;
  
  return (
    <section className={ styles.homepage }>
      <div className={ styles.content }>
        {/* <picture>
          <source media="(min-width: 767px)" srcSet={sunburstImg} />
          <source srcSet={sunburstMobileImg} />
          <img src={ sunburstImg } alt={ title } className={ styles.sunburst } />
        </picture> */}
        <picture>
            <source media="(min-width: 767px)" srcSet={sunburstImg} />
            <source srcSet={sunburstMobileImg} />
            <img src={ sunburstImg } alt="" className={ styles.sunburst } />
        </picture>
        {/* <img src={ sunburstImg } alt={ title } className={ styles.sunburst } /> */}
        <img className={ styles.character } src={ characterimage } alt="" />
        <h1 className={ styles.mainHeading }>
          <picture>
            <source media="(min-width: 767px)" srcSet={headerimage} />
            <source srcSet={headerimageMobile} />
            <img src={ headerimage } alt={ title } />
          </picture>
          <span className="sr-only">{ title }</span>
        </h1>
        <div className={ styles.textContent }>
          <div className={ styles.spacer } />
          <h2 className={ styles.subtitle}>
            <picture>
              <source media="(min-width: 767px)" srcSet={supplementaryimage} />
              <source srcSet={supplementaryimagemobile} />
              <img src={ supplementaryimage } alt={ subtitle } />
            </picture>
            <span className="sr-only">{ subtitle }</span>
          </h2>

          <div className={ styles.spacer2 } />
          { appstore || playstore ? 
            <h2 className={ styles.ctaTitle}>
              <img src={ ctaImg } alt={ ctatitle } />
              <span className="sr-only">{ ctatitle }</span>
            </h2>
            : null
          }
          <div className={ styles.body } dangerouslySetInnerHTML={{ __html: html }} />
          
          <div className={ styles.buttons }>
            { appstore ? 
              <a className={ styles.appstore } href={ appstore } target="_blank" rel="noopener noreferrer"><img src={ appStoreImagePath } alt="Apple App Store icon" /></a>
              : null
            }

            { playstore ? 
              <a className={ styles.playstore } href={ playstore } target="_blank" rel="noopener noreferrer"><img src={ playStoreImagePath } alt="Google Play Store icon" /></a>
              : null
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageTemplate
