import React from 'react'
import styles from './index.module.scss'
import bbtvLogo from '../../img/bbtv-logo.svg';

const Footer = props => {
  
  return (
    <footer className={ styles.footer }>
      <div className={ styles.footerContainer }>
        <div className={ styles.footerLinks }>
          <a 
            className={ styles.footerText }
            href="https://www.bbtv.com/privacy-policy" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
          <a 
            className={ styles.footerText }
            href="https://www.bbtv.com/terms-of-service" 
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>
        </div>
        <div className={ styles.logoGroup }>
          <span className={ styles.logoLead }>Powered By</span>
          <img className={ styles.logo } src={ bbtvLogo } alt="BBTV Interactive Logo" />
        </div>
      </div>
    </footer>
  )
}

export default Footer