import React from "react"
import { graphql } from "gatsby"

// import Layout from "../components/layout"
// import Image from "../components/image"
// import SEO from "../components/seo"
import HomepageTemplate from '../templates/homepage';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import CookieBanner from '../components/CookieBanner';

const IndexPage = ({data}) => {

  return (
    <>
      <Layout props={ { 
        title: data.markdownRemark.frontmatter.title, 
        meta: data.markdownRemark.frontmatter.meta 
        }}
      >
        <HomepageTemplate 
          html={ data.markdownRemark.html }
          title={ data.markdownRemark.frontmatter.title }
          subtitle={ data.markdownRemark.frontmatter.subtitle }
          ctatitle={ data.markdownRemark.frontmatter.ctatitle }
          appstore={ data.markdownRemark.frontmatter.appstore }
          playstore={ data.markdownRemark.frontmatter.playstore }
          appstoreIcon={ data.markdownRemark.frontmatter.appstoreIcon.publicURL }
          playstoreIcon={ data.markdownRemark.frontmatter.playstoreIcon.publicURL }
          headerimage={ data.markdownRemark.frontmatter.headerimage.publicURL }
          headerimageMobile={ data.markdownRemark.frontmatter.headerimageMobile.publicURL }
          supplementaryimage={ data.markdownRemark.frontmatter.supplementaryimage.publicURL }
          supplementaryimagemobile={ data.markdownRemark.frontmatter.supplementaryimagemobile.publicURL }
          characterimage={ data.markdownRemark.frontmatter.characterimage.publicURL }
        />
        <Footer />
        <CookieBanner />
      </Layout>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    markdownRemark(frontmatter:{templateKey: {eq: "homepage"}}) {
      html
      frontmatter {
        title
        meta
        subtitle
        ctatitle
        appstore
        appstoreIcon {
          publicURL
        }
        playstore
        playstoreIcon {
          publicURL
        }
        headerimage {
          publicURL
        }
        headerimageMobile {
          publicURL
        }
        supplementaryimage {
          publicURL
        }
        supplementaryimagemobile {
          publicURL
        }
        characterimage {
          publicURL
        }
      }
    }
  }
`;
